import React, { useCallback, useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import {
  useLazyDashBrdSubsptnQuery,
  useLazyDashbrdSubsptnOnBasisQuery,
} from "../../../services/dashbrdApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function SubscriberGraph() {
  const [CustomSubsData] = useLazyDashBrdSubsptnQuery();
  const [TotalSubscriber] = useLazyDashbrdSubsptnOnBasisQuery();

  const labels = [];

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const [noClient, setNoClient] = useState([]);
  const [cust, setCust] = useState(false);
  const [strtDate, setStrtDate] = useState();
  const [endDate, setEndDate] = useState();
  const [load, setLoad] = useState(false);

  const getCustomData = async () => {
    let res;
    try {
      setLoad(true);
      res = await CustomSubsData({ start: strtDate, end: endDate });
      console.log("res: ", res);
      setNoClient(res?.data?.data);
    } catch (error) {
      console.log("Error in getting custom data", error);
    } finally {
      setLoad(false);
    }
  };

  const customDate = () => {
    if (cust) {
      return (
        <div className="flex flex-col custom_date">
          <div>
            <label htmlFor="" className="">
              From Date
            </label>
            <input type="date" onChange={(e) => setStrtDate(e.target.value)} />
          </div>
          <div>
            <label htmlFor="" className="">
              To Date
            </label>
            <input type="date" onChange={(e) => setEndDate(e.target.value)} />
          </div>
          <button onClick={getCustomData}>apply</button>
        </div>
      );
    }
  };

  const totalSubs = useCallback(
    async (type) => {
      if (type === "custom") {
        setCust(true);
        return;
      }
      let res;
      try {
        setLoad(true);
        res = await TotalSubscriber(type);
        console.log("res: ", res.data);
        setNoClient(res?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setCust(false);
        setLoad(false);
      }
    },
    [TotalSubscriber]
  );

  useEffect(() => {
    totalSubs("daily");
  }, [totalSubs]);

  const data = {
    labels,
    datasets: [
      {
        label: "Subscriber",
        data: noClient,
        borderColor: "#414de1",
        borderWidth: "4",
        pointRadius: "0",
        fill: false,
        lineTension: 0.31645,
        backgroundColor: "#414de1",
        borderCapStyle: "butt",
        borderJoinStyle: "miter",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#28a745",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#ffcc99",
        pointHoverBorderColor: "#f5efef",
        pointHitRadius: 50,
      },
    ],
  };

  return (
    <>
      <div className="graph_chart">
        <h4>Total Subscriptions</h4>
        <div className="date_head d_flx">
          {customDate()}
          <div className="input_box">
            <select
              name="client"
              id="client"
              onChange={(e) => totalSubs(e.target.value)}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>
        <Spin spinning={load} size="medium">
          <Line options={options} data={data} />
        </Spin>
      </div>
    </>
  );
}
