/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import { errorToast, successToast } from "../../../helpers/showToast";
import { UploadMedia } from "../../../services/uploadmedia";
import { Spin } from "antd";
import { isAlphabete } from "../../../utils/validations";
import {
  useCrteBotTempltMutation,
  useEditBotTempltMutation,
  useLazyBotByIdQuery,
} from "../../../services/bottemplate";

const EditTemplate = () => {
  let history = useNavigate();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState();
  const [mediaId, setMediaId] = useState();
  const [profilePic, SetProfilePic] = useState(null);
  const [profileImg, setProfileImg] = useState("/images/frame.png");
  const [createBotTemplates] = useCrteBotTempltMutation();
  const location = useLocation();
  const { state } = location;
  const [BotById] = useLazyBotByIdQuery();
  const [EditBot] = useEditBotTempltMutation();
  const [load, setLoad] = useState(false);
  const [require, setRequire] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      SetProfilePic(file);
    } else {
      SetProfilePic(null);
    }
    setProfileImg(URL.createObjectURL(file));
  };

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await BotById(state?.key);
      console.log("response: ", response?.data?.data);
      const responseData = response?.data?.data;
      setTitle(responseData?.title);
      setDesc(responseData?.description);
      setProfileImg(responseData?.bot_service_image.media_file_url);
      if (!profilePic) {
        setMediaId(responseData?.bot_service_image.id);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (state?.eve === "edit") {
      fetchData();
    }
  }, []);

  const saveBotTemplate = async () => {
    // for Edit Bot template
    if (state?.eve === "edit") {
      if (!title || !desc) {
        console.log("please enter feilds");
        setRequire(true);
        return;
      }
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = await mediaResponse.data.find((media) => media.id);
        }
        let body = {
          title: title,
          description: desc,
          bot_service_image: profilePic ? proMedia?.id : mediaId,
        };
        const response = await EditBot({ botId: state?.key, body });
        if(response?.data?.code === 201){
          successToast(response?.data?.message);
          history("/bottemplates");
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    } else {
      // To create Bot template
      if (!title || !desc) {
        console.log("please enter feilds");
        setRequire(true);
        return;
      }
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = mediaResponse.data.find((media) => media?.id);
        }
        let body = {
          title: title,
          description: desc,
          bot_service_image: profilePic ? proMedia?.id : null,
        };
        const response = await createBotTemplates(body);
        console.log("response: ", response);
        if (response?.data?.code === 201) {
          successToast(response?.data?.message);
          history("/bottemplates");
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
        setTitle("");
        setDesc("");
        setProfileImg("/images/frame.png");
      }
    }
  };

  function TemplatePage() {
    history("/bottemplates");
  }

  const handleTitle = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setTitle(input);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      saveBotTemplate();
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <Spin spinning={load} size={"medium"}>
          <div className="title_bar d_flx">
            <h1>
              {state?.eve === "edit" ? "Edit Bot Template" : "Add Bot Template"}
            </h1>
            <div className="srch_add">
              <button type="button" className="add_btn" onClick={TemplatePage}>
                Back
              </button>
            </div>
          </div>
          <div className="add_form d_flx">
            <div className="w_100">
              <div className=" add_profile">
                <figure>
                  <img src={profileImg} alt="profile" />
                  <input type="file" onChange={handleFileUpload} />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="w_49">
              <LabelInput Name="Title :" />
              <InputBox
                placeholder="Title"
                type="text"
                value={title}
                onChange={handleTitle}
                require={require}
                reqValue={"*Please enter title"}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Description :" />
              <textarea
                className="text_area"
                placeholder="description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {require
                ? !desc && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 15,
                        paddingBottom: 2,
                        margin: 0,
                      }}
                    >
                      Please enter description
                    </p>
                  )
                : null}
            </div>
          </div>
          <button type="button" className="save_btn" onClick={saveBotTemplate}>
            Submit
          </button>
        </Spin>
      </div>
    </>
  );
};
export default EditTemplate;
