/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLazySbsByIdQuery } from "../services/subscription";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SubscriptionModal = ({ setOpen, open, subsId }) => {

  function afterOpenModal() { }
  const [SubsById] = useLazySbsByIdQuery();
  function closeModal() {
    setOpen(false);
    setName("");
    setPrice("");
    setAvatar("");
    setAgent("");
    setQueries("");
    setDescription("");
  }
  const [name, setName] = useState()
  const [price, setPrice] = useState()
  const [avatar, setAvatar] = useState()
  const [agent, setAgent] = useState()
  const [queries, setQueries] = useState()
  const [description, setDescription] = useState()
  const [load, setLoad] = useState(false)


  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          setLoad(true)
          const response = await SubsById(subsId);
          console.log('response: ', response);
          const responseData = response?.data?.data;
          setName(responseData?.name);
          setPrice(responseData?.price);
          setAvatar(responseData?.avatar_count)
          setAgent(responseData?.agent_count)
          setQueries(responseData?.queries_count)
          setDescription(responseData?.description);
        } catch (error) {
          console.log('error: ', error);
        } finally {
          setLoad(false)
        }
      }
      fetchData();
    }
    Modal.setAppElement("#root");
  }, [SubsById, open, subsId]);

  return (
    <div>
      <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="subscription_modal">
          <div className="modal_head d_flx">
            <h2>Subscription Plan</h2>
            <i onClick={closeModal} className="fa-solid fa-xmark"></i>
          </div>
          <div className="subs_one">
            <div className="subs_head d_flx">
              <h3>{name ? name : "---"}</h3>
              <h4>$ {price ? price : "---"}/yr</h4>
            </div>
            <p>{description ? description : 'lorem  ipsum dolor sit amet consectetur adipisicing elit '}</p>
            <ul type="disc">
              <li>You can add upto {agent ? agent : "---"} Agent</li>
              <li>Choose from {avatar ? avatar : "---"} avatar counts</li>
              <li>Train your bot with {queries ? queries : "---"} queries</li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SubscriptionModal;
