import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const subAdminApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        blockSubAdmin: builder.mutation({
            query: ({ subAdminId, body }) => ({
                url: `${END_POINTS.blockSubAdmin}${subAdminId}/`,
                method: 'PUT',
                body,
            }),
        }),
        editSubAdmin: builder.mutation({
            query: ({ subAdminId, body }) => ({
                url: `${END_POINTS.editSubAdmin}${subAdminId}/`,
                method: 'PUT',
                body,
            }),
        }),
        subAdminById: builder.query({
            query: (subAdminId) => ({
                url: `${END_POINTS.subAdminById}${subAdminId}/`,
                method: 'GET',
            }),
        }),
        delSubAdmin: builder.mutation({
            query: (subAdminId) => ({
                url: `${END_POINTS.deleteSubAdmin}${subAdminId}/`,
                method: 'DELETE',
            }),
        }),
        modList: builder.mutation({
            query: () => ({
                url: END_POINTS.moduleListing,
                method: 'GET',
            }),
        }),
        allSubAdmin: builder.mutation({
            query: (body) => ({
                url: END_POINTS.allSubAdmin,
                method: 'POST',
                body,
            }),
        }),
        addSubAdmin: builder.mutation({
            query: (body) => ({
                url: END_POINTS.addSubAdmin,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const
    {
        useBlockSubAdminMutation,
        useEditSubAdminMutation,
        useLazySubAdminByIdQuery,
        useDelSubAdminMutation,
        useModListMutation,
        useAllSubAdminMutation,
        useAddSubAdminMutation,
    } = subAdminApi;