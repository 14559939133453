/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import SearchBox from "../../../components/search";
import SelectBox from "../../../components/filter";
import { Empty, Pagination, Rate, Skeleton } from "antd";
import DeleteModal from "../../../components/deletemodal";
import {
  useDelRatingMutation,
  useRatingByCustmrMutation,
} from "../../../services/mangerating";
import { useLazyClntListingQuery } from "../../../services/client";
import { successToast } from "../../../helpers/showToast";

const Ratings = () => {
  const [ratingData] = useRatingByCustmrMutation();
  const [clientName] = useLazyClntListingQuery();
  const [deleteRating] = useDelRatingMutation();
  const [load, setLoad] = useState(false);
  const [rating, setRating] = useState([]);
  const [current, setCurrent] = useState(1);
  const [client, setClient] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [totalCnt, setTotalCnt] = useState(null);

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        client_id: "",
      };
      const response = await ratingData(body);
      console.log("response: ", response?.data?.data);
      setRating(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [ratingData, current]);

  const handleDelete = async (id) => {
    try {
      const response = await deleteRating(id);
      successToast(response?.data?.message);
    } catch (error) {
      console.log("Error", error);
    } finally {
      fetchData();
    }
  };

  const fetchClientData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: "",
        search_value: "",
      };
      const response = await clientName(body);
      console.log("response: ", response?.data?.data);
      setClient(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [clientName]);

  useEffect(() => {
    fetchData();
    fetchClientData();
  }, [fetchData, fetchClientData]);

  const filterByClient = async (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
    console.log("clientId: ", clientId);
    if (!clientId) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        client_id: clientId ? clientId : "",
      };
      const response = await ratingData(body);
      console.log("response: ", response?.data?.data);
      setRating(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        search_value: search ? search : "",
        client_id: clientId ? clientId : "",
      };
      const response = await ratingData(body);
      console.log("response: ", response?.data?.data);
      setRating(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Ratings</h1>
          <div className="srch_add">
            <SelectBox onChange={filterByClient} value={client} />
            <SearchBox onChange={handleSearch} />
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Agent Name</th>
                  <th>Email</th>
                  <th>Client Name</th>
                  <th>Ratings</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rating?.length ? (
                  rating?.map((subs, index) => (
                    <tr>
                      <td>{++index}</td>
                      <td>
                        <figure className="usr_img">
                          <img
                            src={
                              subs?.image ? subs?.image : "./images/user.jpeg"
                            }
                            alt="img"
                          />
                        </figure>
                      </td>
                      <td>{subs?.agent_name ? subs?.agent_name : "N/A"}</td>
                      <td>{subs?.email ? subs?.email : "N/A"}</td>
                      <td>{subs?.client_name ? subs?.client_name : "N/A"}</td>
                      <td>
                        <span className="rating_star">
                          <Rate
                            disabled
                            defaultValue={subs.ratings}
                            style={{
                              color: "#414de1",
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(
                                  () => handleDelete(subs?.id),
                                  `rating by ${subs?.agent_name}`
                                )
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {rating?.length && totalCnt > 10 ? (
            <Pagination
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Ratings;
