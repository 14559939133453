import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";


const subscriptionApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        sbsById: builder.query({
            query: (id) => ({
                url: END_POINTS.sbsById + id + "/",
                method: 'GET',
            }),
        }),
        deleteSubsrptn: builder.mutation({
            query: (id) => ({
                url: `${END_POINTS.deleteSubscription}${id}/`,
                method: 'DELETE',
            }),
        }),
        editSubscription: builder.mutation({
            query: ({ body, id }) => ({
                url: `${END_POINTS.editSubscription}${id}/`,
                method: 'PUT',
                body,
            }),
        }),
        createSubs: builder.mutation({
            query: (body) => ({
                url: END_POINTS.createSubscription,
                method: 'POST',
                body,
            }),
        }),
        getGracePeriod: builder.query({
            query: () => ({
                url: END_POINTS.getGracePeriod,
                method: 'GET',
            }),
        }),
        getDuration: builder.query({
            query: () => ({
                url: END_POINTS.getDuration,
                method: 'GET',
            }),
        }),
        allSubs: builder.mutation({
            query: (body) => ({
                url: END_POINTS.allSubscription,
                method: 'POST',
                body
            }),
        }),
    }),
});

export const
    {
        useDeleteSubsrptnMutation,
        useEditSubscriptionMutation,
        useLazySbsByIdQuery,
        useCreateSubsMutation,
        useLazyGetDurationQuery,
        useLazyGetGracePeriodQuery,
        useAllSubsMutation,
    } = subscriptionApi;
