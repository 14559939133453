/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import SearchBox from "../../../components/search";
import { useNavigate } from "react-router-dom";
import { Empty, Pagination, Skeleton } from "antd";
import { successToast } from "../../../helpers/showToast";
import DeleteModal from "../../../components/deletemodal";
import {
  useAllSubAdminMutation,
  useBlockSubAdminMutation,
  useDelSubAdminMutation,
} from "../../../services/subadmin";

const SubAdminList = () => {
  let history = useNavigate();

  function SubAdminPage() {
    history("/addsubadmin", { state: { eve: "add" } });
  }
  function editSubAdmin(id) {
    history("/addsubadmin", { state: { key: id, eve: "edit" } });
  }

  const [DeleteSubAdmin] = useDelSubAdminMutation();
  const [BlockSubAdmin] = useBlockSubAdminMutation();
  const [allSubAdmin] = useAllSubAdminMutation();
  const [subAdminData, setSubAdminData] = useState([]);
  const [load, setLoad] = useState(null);
  const [totalCnt, setTotalCnt] = useState(null);
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await allSubAdmin(body);
      console.log("response: ", response?.data?.data);
      setSubAdminData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [allSubAdmin, current]);

  const handleDelete = async (id) => {
    const res = await DeleteSubAdmin(id);
    successToast(res?.message);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const SaveAwayData = async (target, id, name) => {
    console.log("target: ", !target);
    let res;
    try {
      let body = {
        is_active: !target,
      };
      res = await BlockSubAdmin({ subAdminId: id, body });
      console.log("response: ", res);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      if (!target) {
        successToast(name + " is Blocked");
      } else {
        successToast(name + " is Unblocked");
      }
    }
  };

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ? search : "",
        page: current,
        length: 10,
      };
      const response = await allSubAdmin(body);
      console.log("response: ", response?.data?.data);
      setSubAdminData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Subadmin</h1>
          <div className="srch_add">
            <SearchBox onChange={handleSearch} />
            <button type="button" className="add_btn" onClick={SubAdminPage}>
              Add Sub-Admin
            </button>
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Blocked</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subAdminData?.length ? (
                  subAdminData?.map((subAdmin, index) => (
                    <tr key={subAdmin?.id}>
                      <td>{++index}</td>
                      <td>{`${subAdmin?.first_name} ${subAdmin?.last_name}`}</td>
                      <td>
                        <figure className="usr_img">
                          <img
                            src={
                              subAdmin?.profile_picture?.media_file_url !== ""
                                ? subAdmin?.profile_picture?.media_file_url
                                : "./images/user.jpeg"
                            }
                            alt="bot"
                          />
                        </figure>
                      </td>
                      <td>{subAdmin?.email}</td>
                      <td>{subAdmin?.phone_no}</td>
                      <td>
                        {" "}
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={!subAdmin?.is_active}
                            onChange={(e) =>
                              SaveAwayData(
                                e.target.checked,
                                subAdmin?.id,
                                `${subAdmin?.first_name} ${subAdmin?.last_name}`
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() => editSubAdmin(subAdmin?.id)}
                            >
                              <figure>
                                <img src="/images/edit.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(
                                  () => handleDelete(subAdmin?.id),
                                  `${subAdmin?.first_name} ${subAdmin?.last_name}`
                                )
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {subAdminData?.length && totalCnt > 10 ? (
            <Pagination
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default SubAdminList;
