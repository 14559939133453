import { Dropdown, Empty } from "antd";
import React, { useState } from "react";
import { useLazyHdrNotificationQuery } from "../services/notifications";
import timesAgo from "../utils/timeago";

const Notification = () => {
  const [noticeData] = useLazyHdrNotificationQuery();
  const [data, setData] = useState();

  const items = [
    {
      label: (
        <div className="drop_down">
          <ul className="noti_fication">
            {data?.length ? (
              data?.map((subs) => (
                <li className="d_flx" key={subs?.id}>
                  <div className="noti_cnt">
                    <figure>
                      <img src="/images/noti.svg" alt="notification"></img>
                    </figure>
                    <p>{subs?.title}</p>
                  </div>
                  <span>{timesAgo(subs?.created_at)}</span>
                </li>
              ))
            ) : (
              <Empty />
            )}
          </ul>
        </div>
      ),
      key: "0",
    },
  ];

  const notification = async () => {
    try {
      const response = await noticeData();
      setData(response?.data?.data);
      // setTotalCnt(response.data.total_count)
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <figure onClick={notification} style={{ cursor: "pointer" }}>
        <img src="/images/notification-bing.svg" alt="notification"></img>
      </figure>
    </Dropdown>
  );
};

export default Notification;
