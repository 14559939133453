// usePermission.js
import { useMemo } from "react";
import { useAppSelector } from "./store";
import { getRolePermission } from "../reducers/authSlice";

function usePermission() {
  const user = useAppSelector(getRolePermission);
  return useMemo(() => user, [user]);
}

export default usePermission;
