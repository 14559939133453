/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddSubAdminMutation,
  useEditSubAdminMutation,
  useLazySubAdminByIdQuery,
  useModListMutation,
} from "../../../services/subadmin";
import { errorToast, successToast } from "../../../helpers/showToast";
import {
  isAlphabete,
  isEmail,
  validMobileNo,
} from "../../../utils/validations";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import { UploadMedia } from "../../../services/uploadmedia";
import { Spin } from "antd";

const EditAdmin = () => {
  let history = useNavigate();

  function SubadminPage() {
    history("/subadmin");
  }

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [mediaId, setMediaId] = useState(null);
  const [load, setLoad] = useState(false);
  const [require, setRequire] = useState(false);
  const [profilePic, SetProfilePic] = useState(null);
  const [SubAdminId] = useLazySubAdminByIdQuery();
  const [profileImg, setProfileImg] = useState("/images/frame.png");
  const location = useLocation();
  const { state } = location;
  const [addSubAdmin] = useAddSubAdminMutation();
  const [moduleListing] = useModListMutation();
  const [EditSubAdmin] = useEditSubAdminMutation();
  const [rolePermissions, setRolePermissions] = useState([
    {
      module: 1,
      module_name: "Dashboard",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 2,
      module_name: "Manage Subscription",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 3,
      module_name: "Manage Client",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 4,
      module_name: "Manage Customer",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 5,
      module_name: "Manage Bot Template",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 6,
      module_name: "Manage Bot",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 7,
      module_name: "Manage Chat",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 8,
      module_name: "Manage Rating",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 9,
      module_name: "Manage Support",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
    {
      module: 10,
      module_name: "Notification",
      is_add: false,
      is_view: false,
      is_edit: false,
      is_delete: false,
    },
  ]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      SetProfilePic(file);
      setProfileImg(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (state?.eve === "edit") {
      const fetchData = async () => {
        try {
          setLoad(true);
          const response = await SubAdminId(state?.key);
          console.log("response: ", response);
          const responseData = response?.data?.data?.data;
          const fetchedRolePermissions = response?.data?.data?.role_permissions;
          const updatedRolePermissions = rolePermissions?.map((permission) => {
            const fetchedPermission = fetchedRolePermissions?.find(
              (fetchedPermission) =>
                fetchedPermission?.module === permission?.module
            );
            if (fetchedPermission) {
              return {
                ...permission,
                is_view: fetchedPermission.is_view,
                is_add: fetchedPermission.is_add,
                is_edit: fetchedPermission.is_edit,
                is_delete: fetchedPermission.is_delete,
              };
            }
            return permission;
          });
          setRolePermissions(updatedRolePermissions);
          setFirstName(responseData?.first_name);
          setLastName(responseData?.last_name);
          setEmail(responseData?.email);
          setProfileImg(responseData?.profile_picture?.media_file_url);
          setPhone(responseData?.phone_no);
          if (!profilePic) {
            setMediaId(responseData?.profile_picture?.id);
          }
        } catch (error) {
          console.log("error: ", error);
        } finally {
          setLoad(false);
        }
      };
      fetchData();
    }
  }, [SubAdminId, moduleListing]);

  const handleRolePermissionChange = (isChecked, moduleId, permissionType) => {
    setRolePermissions((prevPermissions) => {
      return prevPermissions.map((permission) => {
        if (permission.module === moduleId) {
          return {
            ...permission,
            [permissionType]: isChecked,
          };
        }
        return permission;
      });
    });
  };

  const saveClient = async () => {
    if (state.eve === "add") {
      if (
        !email ||
        !firstName ||
        !lastName ||
        !phone ||
        !validMobileNo(phone) ||
        !isEmail(email)
      ) {
        setRequire(true);
        return;
      }
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = mediaResponse.data.find((media) => media.id);
        }
        let body = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          profile_picture: profilePic ? proMedia?.id : null,
          phone_no: phone,
          role_permission: rolePermissions,
        };
        const response = await addSubAdmin(body);
        console.log("response: ", response);
        successToast(response?.data?.message);
        history("/subadmin");
      } catch (error) {
        errorToast(error);
        console.log("error: ", error);
      } finally {
        setLoad(false);
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setProfileImg("/images/frame.png");
      }
    } else if (state?.eve === "edit") {
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = await mediaResponse.data.find((media) => media?.id);
        }
        let body = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          profile_picture: profilePic ? proMedia?.id : mediaId,
          phone_no: phone,
          role_permission: rolePermissions,
        };
        const response = await EditSubAdmin({ subAdminId: state?.key, body });
        if (response?.data?.code === 201) {
          successToast(response?.data?.message);
          history("/subadmin");
        }
      } catch (error) {
        errorToast(error);
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    }
  };

  const handlePhone = (e) => {
    const input = e.target.value;
    const number = parseFloat(input);
    if (!isNaN(number)) {
      setPhone(number);
    } else {
      setPhone("");
    }
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFirstName(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLastName(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveClient(e);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <Spin spinning={load} size="medium">
          <div className="title_bar d_flx">
            <h1>{state.eve === "add" ? "Add Sub Admin" : "Edit Sub Admin"}</h1>
            <div className="srch_add">
              <button type="button" className="add_btn" onClick={SubadminPage}>
                Back
              </button>
            </div>
          </div>
          <div className="add_form d_flx">
            <div className="w_100">
              <div className=" add_profile">
                <figure>
                  <img
                    src={profileImg ? profileImg : "/images/frame.png"}
                    alt="profile"
                  />
                  <input type="file" onChange={handleFileUpload} />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="w_49">
              <LabelInput Name="First Name :" />
              <InputBox
                placeholder="First Name"
                type="text"
                value={firstName}
                onChange={handleFname}
                require={require}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Last Name :" />
              <InputBox
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={handleLname}
                require={require}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Email :" />
              <InputBox
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Phone Number :" />
              <InputBox
                placeholder="Phone Number"
                type="text"
                value={phone}
                onChange={handlePhone}
                require={require}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>
          <div className="table_box mb_50">
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>View</th>
                  <th>Add</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                {load === false &&
                  rolePermissions?.map((permission) => (
                    <tr key={permission?.module}>
                      <td>{permission?.module_name}</td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={permission.is_view}
                          onChange={(e) =>
                            handleRolePermissionChange(
                              e.target.checked,
                              permission?.module,
                              "is_view"
                            )
                          }
                          onKeyDown={handleKeyPress}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={permission.is_view}
                          onChange={(e) =>
                            handleRolePermissionChange(
                              e.target.checked,
                              permission?.module,
                              "is_add"
                            )
                          }
                          onKeyDown={handleKeyPress}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={permission.is_edit}
                          onChange={(e) =>
                            handleRolePermissionChange(
                              e.target.checked,
                              permission?.module,
                              "is_edit"
                            )
                          }
                          onKeyDown={handleKeyPress}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={permission.is_delete}
                          onChange={(e) =>
                            handleRolePermissionChange(
                              e.target.checked,
                              permission?.module,
                              "is_delete"
                            )
                          }
                          onKeyDown={handleKeyPress}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <button type="button" className="save_btn" onClick={saveClient}>
            Submit
          </button>
        </Spin>
      </div>
    </>
  );
};
export default EditAdmin;
