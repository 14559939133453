import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import {
  useLazyDashBrdAllClientDataQuery,
  useLazyDashbrdClientOnBasisQuery,
} from "../../../services/dashbrdApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ClientGraph() {
  const [CustomClientData] = useLazyDashBrdAllClientDataQuery();
  const [TotalClient] = useLazyDashbrdClientOnBasisQuery();

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const [noClient, setNoClient] = useState([]);
  const [cust, setCust] = useState(false);
  const [strtDate, setStrtDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [load, setLoad] = useState(false);
  const chartRef = useRef();

  const getCustomData = async () => {
    let res;
    try {
      setLoad(true);
      res = await CustomClientData({ start: strtDate, end: endDate });
      console.log("res: ", res);
      setNoClient(res?.data?.data);
    } catch (error) {
      console.log("Error in getting custom data", error);
    } finally {
      setLoad(false);
    }
  };

  const customDate = () => {
    if (cust) {
      return (
        <div className="flex flex-col custom_date">
          <div>
            <label htmlFor="" className="">
              From Date
            </label>
            <input type="date" onChange={(e) => setStrtDate(e.target.value)} />
          </div>
          <div>
            <label htmlFor="" className="">
              To Date
            </label>
            <input type="date" onChange={(e) => setEndDate(e.target.value)} />
          </div>
          <button onClick={getCustomData}>apply</button>
        </div>
      );
    }
  };

  const totalClient = useCallback(
    async (type) => {
      if (type === "custom") {
        setCust(true);
      } else {
        let res;
        try {
          setLoad(true);
          res = await TotalClient(type);
          console.log("res: ", res?.data?.data);
          setNoClient(res?.data?.data);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          setCust(false);
          setLoad(false);
        }
      }
    },
    [TotalClient]
  );

  useEffect(() => {
    totalClient("daily");
  }, [totalClient]);

  const data = {
    // labels,
    datasets: [
      {
        label: "Client",
        data: noClient,
        borderColor: "#414de1",
        borderWidth: "4",
        pointRadius: "0",
        fill: false,
        lineTension: 0.31645,
        backgroundColor: "#414de1",
        borderCapStyle: "butt",
        borderJoinStyle: "miter",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#28a745",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#ffcc99",
        pointHoverBorderColor: "#f5efef",
        pointHitRadius: 50,
      },
    ],
  };

  return (
    <>
      <div className="graph_chart">
        <h4>Total Client</h4>
        <div className="date_head d_flx">
          {customDate()}
          <div className="input_box">
            <select
              name="client"
              id="client"
              onChange={(e) => totalClient(e.target.value)}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>
        <Spin spinning={load} size="medium">
          <Line ref={chartRef} options={options} data={data} />
        </Spin>
      </div>
    </>
  );
}
