import React, { useState } from "react";
import Email from "../../../components/email";
import FormHead from "../../../components/formhead";
import LoginButton from "../../../components/loginbutton";
import VerifyModal from "../../../components/modal";
import { errorToast, successToast } from "../../../helpers/showToast";
import { useUserFgtPassMutation } from "../../../services/api";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const FormForgot = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [forgetPassword] = useUserFgtPassMutation();
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  const ResetPage = async () => {
    if (!email) {
      console.log("Please enter details");
      setRequire(true);
      return;
    }
    let response;
    let body = {
      email: email,
    };
    try {
      setLoad(true);
      response = await forgetPassword(body);
      console.log("login data", response.data);
      if (response.data.code === 200) {
        successToast(response?.data?.message);
        setOpen(true);
      } else {
        errorToast(response?.error?.data?.message);
        console.log("Please enter correct credentials");
      }
    } catch (error) {
      console.log("error: ", error);
      errorToast(response?.error?.data?.message);
    } finally {
      setLoad(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      ResetPage(e);
    }
  };

  return (
    <>
      <section className="login_page">
        <Spin spinning={load}>
          <div className="login_bx">
            <Link to="/">
              <i className="fa-solid fa-chevron-left back_frgt"></i>
            </Link>
            <div className="lg_frm">
              <FormHead title=" Reset your password" />
              <Email
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <LoginButton name="Reset Password" click={ResetPage} />
            </div>
          </div>
        </Spin>
      </section>
      <VerifyModal setOpen={setOpen} open={open} email={email} />
    </>
  );
};

export default FormForgot;
