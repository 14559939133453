export const DaysCount = (value) => {
  const durationMap = {
    1: "1 day",
    2: "2 days",
    3: "3 days",
    4: "4 days",
    5: "5 days",
    6: "6 days",
    7: "1 week",
    14: "2 weeks",
    21: "3 weeks",
    28: "4 weeks",
    30: "1 month",
    60: "2 months",
    90: "3 months",
    120: "4 months",
    150: "5 months",
    180: "6 months",
    210: "7 months",
    240: "8 months",
    270: "9 months",
    300: "10 months",
    330: "11 months",
    360: "1 year",
    720: "2 years",
  };

  return durationMap[value] || "Unknown duration";
};

export const DaysById = (value) => {
  const durationMap = {
    1: "1 day",
    2: "2 days",
    3: "3 days",
    4: "4 days",
    5: "5 days",
    6: "6 days",
    7: "1 week",
    14: "2 weeks",
    21: "3 weeks",
    28: "4 weeks",
    30: "1 month",
    60: "2 months",
    90: "3 months",
    120: "4 months",
    150: "5 months",
    180: "6 months",
    210: "7 months",
    240: "8 months",
    270: "9 months",
    300: "10 months",
    330: "11 months",
    360: "1 year",
    720: "2 years",
  };

  return durationMap[value] || "Unknown duration";
};
