/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Modal from "react-modal";
import LoginButton from "./loginbutton";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../helpers/showToast";
import { Spin } from "antd";
import { useUserFgtPassOtpMutation } from "../services/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const VerifyModal = ({ setOpen, open, email, data }) => {
  const [forgetPasswordOtp] = useUserFgtPassOtpMutation();
  const [otp, setOtp] = useState("");
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  let history = useNavigate();

  function afterOpenModal() {}

  function closeModal() {
    setOpen(false);
    setOtp("");
  }

  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!otp) {
        return showError("Please enter otp");
      }
    }
    return null;
  };

  const verify = async () => {
    if (!otp) {
      console.log("please enter");
      setRequire(true);
      return;
    }
    let body = {
      otp: otp,
      email: email,
    };
    let response;
    try {
      setLoad(true);
      response = await forgetPasswordOtp(body);
      console.log(response.data);
      if (response.data.code === 200) {
        history("/resetpassword", { state: { key: email } });
        successToast(response.data.message);
      }
    } catch (error) {
      errorToast(response.error.data.message);
    } finally {
      closeModal();
      setLoad(false);
    }
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      verify();
    }
  };

  return (
    <div>
      <Spin spinning={load} size="medium">
        <Modal
          isOpen={open}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="verify_email">
            <figure className="email_icon">
              <img src="/images/email.svg" alt="image" />
            </figure>
            <h2>Verify your email address</h2>

            <div className="otp-input">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "1.5rem",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                }}
                renderInput={(props) => (
                  <input {...props} onKeyDown={handleOnKeyPress} />
                )}
              />
              {validateInput()}
            </div>
            <p>
              We sent you an email to verify the email. Please check the email
              to OTP
            </p>
            <LoginButton name="verify" click={verify} />
          </div>
        </Modal>
      </Spin>
    </div>
  );
};
export default VerifyModal;
