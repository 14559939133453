import React, { useState } from "react";
import Password from "../../../components/password";
import FormHead from "../../../components/formhead";
import LoginButton from "../../../components/loginbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { isPasswordPattern, matchPassword } from "../../../utils/validations";
import PasswordCnf from "../../../components/passwordCnf";
import { successToast } from "../../../helpers/showToast";
import { useNewPasswordMutation } from "../../../services/api";
import { Spin } from "antd";

const ResetForm = () => {
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPassward] = useNewPasswordMutation();
  const location = useLocation();
  const { state } = location;
  const [require, setRequired] = useState(false);
  const [load, setLoad] = useState(false);

  let history = useNavigate();

  const LoginPage = async () => {
    if (
      !pass ||
      !confirmPass ||
      !matchPassword(pass, confirmPass) ||
      !isPasswordPattern(pass)
    ) {
      setRequired(true);
      return;
    }
    if (pass === confirmPass) {
      let body = {
        email: state.key,
        new_password: confirmPass,
      };
      console.log("state.key: ", state.key);
      try {
        setLoad(true);
        const response = await newPassward(body);
        console.log("login data", response.data);
        console.log("response.data.code: ", response.data.code);
        successToast(response.data.message);
        if (response.data.code === 201) {
          history("/");
        } else {
          console.log("Please enter correct credentials");
        }
      } catch (error) {
        console.log("enter correct credentials");
        console.error("Error:", error);
      } finally {
        setLoad(false);
      }
    } else {
      console.log("pass and cfnpass should be same");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      LoginPage(e);
    }
  };

  return (
    <>
      <section className="login_page">
        <Spin spinning={load}>
          <div className="login_bx">
            <div className="lg_frm">
              <FormHead title="Reset your password" />
              <Password
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <PasswordCnf
                placeholder="Confirm Password"
                cfmPass={pass}
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <LoginButton name="Reset Password" click={LoginPage} />
            </div>
          </div>
        </Spin>
      </section>
    </>
  );
};

export default ResetForm;
