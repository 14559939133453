/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useLazyDashBrdCardQuery } from "../../../services/dashbrdApi";

const Card = () => {
  const [cardData] = useLazyDashBrdCardQuery();
  const [totalClient, setTotalClient] = useState();
  const [totalAgent, setTotalAgent] = useState();
  const [newClients, setNewClients] = useState();
  const [activeSubs, setActiveSubs] = useState();
  const [expireSubs, setExpireSubs] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [activeChat, setActiveChat] = useState();
  const [currentTrail, setCurrentTrail] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        const response = await cardData();
        const responseData = response?.data?.data;
        console.log("response: ", response?.data?.data);
        setTotalClient(responseData?.total_clients);
        setTotalAgent(responseData?.total_agents);
        setNewClients(responseData?.new_clients);
        setActiveSubs(responseData?.active_subscriptions);
        setExpireSubs(responseData?.expired_subscriptions);
        setTotalRevenue(responseData?.total_revenue);
        setActiveChat(responseData?.active_chat);
        setCurrentTrail(responseData?.currently_on_trial);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [cardData]);

  return (
    <div className="d_flx">
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/people1.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{totalClient ? totalClient : "0"}</h2>
          <p>No Of Clients</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/user.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{newClients ? newClients : "0"}</h2>
          <p>New Clients</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/Subscriptionne.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{activeSubs ? activeSubs : "0"}</h2>
          <p>Active Subscriptions</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/subscription_expire.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{expireSubs ? expireSubs : "0"}</h2>
          <p>Expired Subscriptions</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/chat7.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{activeChat ? activeChat : "0"}</h2>
          <p>Active Chat</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/free-trial.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{currentTrail ? currentTrail : "0"}</h2>
          <p>Currently On Trial</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/traffic.svg" alt="image"></img>
        </figure>
        <div>
          <Tooltip title={totalRevenue ? totalRevenue : "0"}>
            <h2 className="truncate">
              {totalRevenue ? "$" + totalRevenue : "0"}
            </h2>
          </Tooltip>
          <p>Total Revenue</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/people1.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{totalAgent ? totalAgent : "0"}</h2>
          <p>Total Agents</p>
        </div>
      </div>
    </div>
  );
};
export default Card;
