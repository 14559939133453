import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const customerSupportApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerSupport: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getCustomerSupport,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const
    {
        useGetCustomerSupportMutation,
    } = customerSupportApi;
