/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import SearchBox from "../../../components/search";
import SelectBox from "../../../components/filter";
import { Empty, Pagination, Skeleton } from "antd";
import { successToast } from "../../../helpers/showToast";
import DeleteModal from "../../../components/deletemodal";
import { useLazyClntListingQuery } from "../../../services/client";
import {
  useClientBotMutation,
  useDelClientBotMutation,
} from "../../../services/clientbot";
const Bots = () => {
  const [clientBot, setClientBot] = useState();
  const [searchBot] = useClientBotMutation();
  const [client, setClient] = useState([]);
  const [clientId, setClientId] = useState();
  const [clientName] = useLazyClntListingQuery();
  const [load, setLoad] = useState(false);
  const [DeleteClientBot] = useDelClientBotMutation();

  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState();

  const onChange = (page) => {
    console.log(page);
    console.log(current);
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        client_id: "",
      };
      const response = await searchBot(body);
      console.log("response: ", response.data.data);
      setClientBot(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [searchBot, current]);

  const handleDelete = async (id) => {
    console.log("id: ", id);
    let res;
    try {
      res = await DeleteClientBot(id);
      successToast(res.data.message);
    } catch (error) {
      console.log("Error", error);
    } finally {
      fetchData();
    }
  };

  const fetchClientData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: "",
        search_value: "",
      };
      const response = await clientName(body);
      console.log("response: ", response?.data?.data);
      setClient(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [clientName]);

  useEffect(() => {
    fetchData();
    fetchClientData();
  }, [fetchClientData, fetchData]);

  const filterByClient = async (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
    console.log("clientId: ", clientId);
    if (!clientId) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        client_id: clientId ? clientId : "",
      };
      const response = await searchBot(body);
      console.log("response: ", response.data.data);
      setClientBot(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleSearch = async (event) => {
    const srchBot = event.target.value;
    if (!srchBot) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        search_value: srchBot ? srchBot : "",
        client_id: clientId ? clientId : "",
      };
      const response = await searchBot(body);
      console.log("response: ", response.data.data);
      setClientBot(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Bots</h1>
          <div className="srch_add">
            <SelectBox onChange={filterByClient} value={client} />
            <SearchBox onChange={handleSearch} />
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Client Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clientBot?.length ? (
                  clientBot?.map((clientBot, index) => (
                    <tr key={clientBot?.id}>
                      <td>{++index}</td>
                      <td>{clientBot?.bot_name}</td>
                      <td>
                        <figure className="bot_img">
                          {" "}
                          <img
                            src={clientBot?.bot_avatar?.image?.media_file_url}
                            alt="bot"
                          />{" "}
                        </figure>{" "}
                      </td>
                      <td>{clientBot?.created_by?.first_name}</td>
                      <td>
                        <p className="description_bx">
                          {clientBot?.description}
                        </p>
                      </td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button type="button">
                              <figure
                                onClick={() =>
                                  DeleteModal(
                                    () => handleDelete(clientBot?.id),
                                    clientBot?.bot_name
                                  )
                                }
                              >
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
            {clientBot?.length && totalCnt > 10 ? (
              <Pagination
                defaultCurrent={1}
                current={current}
                onChange={onChange}
                total={totalCnt}
              />
            ) : null}
          </Skeleton>
        </div>
      </div>
    </>
  );
};
export default Bots;
