import { API_URL } from "../constants";
import { errorToast } from "../helpers/showToast";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { STORAGE_KEYS, getData, removeData } from "../constants/storage";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = getData(STORAGE_KEYS.token);
    headers.set("Content-Type", "application/json");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch({ type: "UNAUTHORIZED" });
    removeData(STORAGE_KEYS.token);
    removeData(STORAGE_KEYS.userData);
    errorToast("Session Expired");
    window.location.href = "/";
  }

  return result;
};

const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});

export default emptySplitApi;
