/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import SearchBox from "../../../components/search";
import { Empty, Pagination, Skeleton } from "antd";
import { useGetCustomerSupportMutation } from "../../../services/customersupport";

const CustomerSupport = () => {
  const [getCustomerSupport] = useGetCustomerSupportMutation();
  const [current, setCurrent] = useState(1);
  const [totalCnt, setTotalCnt] = useState(null);
  const [custData, setCustData] = useState([]);
  const [load, setLoad] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await getCustomerSupport(body);
      const responseData = response?.data?.data;
      console.log("response: ", responseData);
      setTotalCnt(response?.data?.total_count);
      setCustData(responseData);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getCustomerSupport, current]);

  const onChange = (page) => {
    setCurrent(page);
  };

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        search_value: search ? search : "",
      };
      const response = await getCustomerSupport(body);
      const responseData = response?.data?.data;
      console.log("response: ", responseData);
      setTotalCnt(response?.data?.total_count);
      setCustData(responseData);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Customer Support</h1>
          <div className="srch_add">
            <SearchBox onChange={handleSearch} />
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Queries</th>
                  <th>Description</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {custData?.length
                  ? custData?.map((data, index) => (
                      <tr key={data?.id}>
                        <td>{++index}</td>
                        <td>{data?.customer_query_first_name}</td>
                        <td>{data?.customer_query_email}</td>
                        <td>{data?.customer_query_phone_no}</td>
                        <td>
                          <p>{data?.queries} </p>
                        </td>
                        <td>
                          {" "}
                          <p>{data?.description} </p>
                        </td>
                        {/* <td>Pending</td> */}
                      </tr>
                    ))
                  : !load && <Empty />}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {custData?.length && totalCnt > 10 ? (
            <Pagination
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default CustomerSupport;
