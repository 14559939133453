import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";


const manageClientApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        clntListing: builder.query({
            query: () => ({
                url: END_POINTS.getClientList,
                method: 'GET',
            }),
        }),
        clientById: builder.query({
            query: (clientId) => ({
                url: `${END_POINTS.clientById}${clientId}/`,
                method: 'GET',
            }),
        }),
        delClient: builder.mutation({
            query: (clientId) => ({
                url: `${END_POINTS.deleteClient}${clientId}/`,
                method: 'DELETE',
            }),
        }),
        editClinet: builder.mutation({
            query: ({clientId, body}) => ({
                url: `${END_POINTS.editClient}${clientId}/`,
                method: 'PUT',
                body
            }),
        }),
        allClnt: builder.mutation({
            query: (body) => ({
                url: END_POINTS.allClient,
                method: 'POST',
                body,
            }),
        }),
        addClnt: builder.mutation({
            query: (body) => ({
                url: END_POINTS.addClient,
                method: 'POST',
                body,
            }),
        }),
        getTrailPeriod: builder.query({
            query: () => ({
                url: END_POINTS.getTrialPeriod,
                method: 'GET',
            }),
        }),
    }),
});

export const
    {
        useLazyClntListingQuery,
        useLazyClientByIdQuery,
        useDelClientMutation,
        useEditClinetMutation,
        useAllClntMutation,
        useAddClntMutation,
        useLazyGetTrailPeriodQuery,
    } = manageClientApi;
