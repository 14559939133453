/* eslint-disable jsx-a11y/img-redundant-alt */
import SearchBox from "../../../components/search";
import { useNavigate } from "react-router-dom";
import SubscriptionModal from "../../../components/subscriptionmodal";
import { successToast } from "../../../helpers/showToast";
import { DaysCount } from "../../../utils/daysCount";
import DeleteModal from "../../../components/deletemodal";
import React, { useCallback, useEffect, useState } from "react";
import { Empty, Pagination, Skeleton } from "antd";
import {
  useAllSubsMutation,
  useDeleteSubsrptnMutation,
} from "../../../services/subscription";

const Subscription = () => {
  const [open, setOpen] = useState(false);
  const [subsId, setSubsId] = useState();
  const [search, setSrchBot] = useState("");
  const [subsData, setSubsData] = useState([]);
  const [allSubs] = useAllSubsMutation();
  const [DeleteSubscription] = useDeleteSubsrptnMutation();
  const [load, setLoad] = useState(false);
  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState();

  const onChange = (page) => {
    setCurrent(page);
  };

  let history = useNavigate();

  function AddSubscription() {
    history("/addsubscription", { state: { eve: "add" } });
  }
  function EditSubscription(id) {
    history("/addsubscription", { state: { key: id, eve: "edit" } });
  }

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await allSubs(body);
      setSubsData(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [allSubs, search, current]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async (id, name) => {
    try {
      setLoad(true);
      const res = await DeleteSubscription(id);
      console.log("res: ", res);
      successToast(res.data.message);
    } catch {
    } finally {
      fetchData();
      setLoad(false);
    }
  };

  const getSubsDetails = (id) => {
    setOpen(true);
    setSubsId(id);
  };

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        search_value: search ? search : "",
      };
      const response = await allSubs(body);
      setSubsData(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Subscription</h1>
          <div className="srch_add">
            <SearchBox onChange={handleSearch} />
            <button type="button" className="add_btn" onClick={AddSubscription}>
              Add Subscription
            </button>
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Duration</th>
                  <th>Price</th>
                  <th>Grace period</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subsData?.length ? (
                  subsData?.map((subs, index) => (
                    <tr key={subs?.id}>
                      <td>{++index}</td>
                      <td>{subs?.name}</td>
                      <td>{subs?.description}</td>
                      <td>{DaysCount(subs?.duration)}</td>
                      <td>{subs?.price}</td>
                      <td>{DaysCount(subs?.grace_period)}</td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() => getSubsDetails(subs?.id)}
                            >
                              <figure>
                                <img src="/images/eye_n.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() => EditSubscription(subs?.id)}
                            >
                              <figure>
                                <img src="/images/edit.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(
                                  () => handleDelete(subs?.id),
                                  subs?.name
                                )
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {subsData?.length && totalCnt > 10 ? (
            <Pagination
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
      <SubscriptionModal setOpen={setOpen} open={open} subsId={subsId} />
    </>
  );
};
export default Subscription;
