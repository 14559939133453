/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import Card from "./features/card";
import { ClientGraph } from "./features/clientgraph";
import { RevenueGraph } from "./features/revenuegraph";
import { SubscriberGraph } from "./features/subscriber";
import { NewClient } from "./features/newClient";

const Dashboard = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <Card />
          <div className="grph_both d_flx">
            <ClientGraph />
            <RevenueGraph />
            <SubscriberGraph />
            <NewClient/>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default Dashboard;
