import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";


const manageRatingApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        ratingByCustmr: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getRatingByCust,
                method: 'POST',
                body,
            }),
        }),
        delRating: builder.mutation({
            query: (id) => ({
                url: END_POINTS.deleteRating + id + "/",
                method: 'DELETE',
            }),
        }),
    }),
});

export const
    {
        useDelRatingMutation,
        useRatingByCustmrMutation,
    } = manageRatingApi;
