/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import Clients from "./features/clients";

const ManageClients = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <Clients />
        </section>
      </Layout>
    </>
  );
};
export default ManageClients;
