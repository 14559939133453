/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import NotificationList from "./features/notificationlist";

const Notification = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <NotificationList />
        </section>
      </Layout>
    </>
  );
};
export default Notification;
