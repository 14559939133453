export const isAlphabete = (input) => {
  const alphabeticPattern = /^[a-zA-Z\s]*$/
  return alphabeticPattern.test(input)
};

export const isPasswordPattern = (password) => {
  const reg = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return reg.test(password);
};

export const matchPassword = (newpassword, confirmPassword) => {
  if (newpassword === confirmPassword) {
    return true;
  }
  return false;
};

export const isEmail = (email) => {
  let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};


export const validOTP = (otp) => {
  if (otp.length < 4) {
    return false;
  }
  return true;
};

export const validMobileNo = (number) => {
  const phoneNoPattern = /^[1-9]\d{9}$/;
  if (phoneNoPattern.test(number)) {
    return true;
  }
  return false;
};
