import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Email from "../../../components/email";
import Password from "../../../components/password";
import LoginButton from "../../../components/loginbutton";
import FormHead from "../../../components/formhead";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { STORAGE_KEYS, getData, storeData } from "../../../constants/storage";
import { errorToast, successToast } from "../../../helpers/showToast";
import { useLogInMutation } from "../../../services/api";
import { setCredentials, setRolePermission } from "../../../reducers/authSlice";
import { Spin } from "antd";

const FormLogin = () => {
  const [logIn] = useLogInMutation();
  const dispatch = useDispatch();
  let history = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const token = getData(STORAGE_KEYS.token);
    if (token) {
      history("/dashboard");
    } else {
      history("/");
    }
  }, []);

  const Login = async (e) => {
    const fcmToken = getData(STORAGE_KEYS.fcmToken);
    let body = {
      email: email,
      password: pass,
      device_type: "Web",
      device_token: fcmToken,
    };
    if (!email || !pass) {
      setRequire(true);
      return;
    }
    let response;
    try {
      setLoad(true);
      response = await logIn(body);
      console.log("response:;;;;;;;;;;; ", response);
      const userDetails = response?.data?.data?.data;
      const to_ken = response?.data?.data?.access_token;
      const rolePermission = response?.data?.data?.role_permissions;
      if (response.data.code === 200) {
        storeData(STORAGE_KEYS?.userData, userDetails);
        storeData(STORAGE_KEYS?.token, to_ken);
        storeData(STORAGE_KEYS?.rolePermission, rolePermission);
        successToast(response?.data?.message);
        dispatch(
          setCredentials({
            user: userDetails,
            token: to_ken,
          })
        );
        dispatch(
          setRolePermission({
            rolePermission: rolePermission,
          })
        );
        history("/dashboard");
      }
    } catch (error) {
      errorToast(response?.error?.data?.message);
    } finally {
      setLoad(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Login(e);
    }
  };

  return (
    <>
      <section className="login_page">
        <Spin size="large" spinning={load}>
          <div className="login_bx">
            <div className="lg_frm">
              <FormHead title="Login to TunixBot" />
              <Email
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <Password
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                require={require}
                login={true}
                onKeyDown={handleKeyPress}
              />
              <div className="d_flx mb_40 jst_right">
                <Link to="/forgotpassword">Forgot Password?</Link>
              </div>
              <LoginButton name="Login" click={Login} />
            </div>
          </div>
        </Spin>
      </section>
    </>
  );
};

export default FormLogin;
