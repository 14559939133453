/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchBox from "../../../components/search";
import SelectBox from "../../../components/filter";
import { useParams } from "react-router-dom";
import {
  useGetCustomerListMutation,
  useLazyChatHistoryQuery,
  useLazyGetClientAgentsQuery,
} from "../../../services/clientview";
import { Drawer, Empty, Pagination } from "antd";

const ChatList = () => {
  const [isActive, setIsActive] = useState(false);
  const [agentName] = useLazyGetClientAgentsQuery();
  const [custData] = useGetCustomerListMutation();
  const [chatHistory] = useLazyChatHistoryQuery();
  const [client, setClient] = useState([]);
  const [chatHistoryData, setChatHistoryData] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [custName, setCustName] = useState(null);
  const chatContainerRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const [totalCnt, setTotalCnt] = useState(null);
  const { id } = useParams();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        setLoad(true);
        const response = await agentName(id);
        console.log("response: ", response?.data?.data);
        setClient(response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchClientData();
  }, [agentName, id]);

  const fetchCustomerData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        agent_id: "",
        search_value: "",
      };
      const response = await custData({ body, id });
      console.log("response: ", response?.data?.data);
      setCustomerList(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [custData, id, current]);

  useEffect(() => {
    fetchCustomerData();
  }, [fetchCustomerData]);

  const filterByAgent = async (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
    console.log("clientId: ", clientId);
    if (!clientId) {
      fetchCustomerData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        agent_id: clientId,
      };
      const response = await custData({ body, id });
      console.log("response: ", response?.data?.data);
      setCustomerList(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleSearch = async (event) => {
    const srchBot = event.target.value;
    if (!srchBot) {
      fetchCustomerData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: srchBot,
        agent_id: clientId,
      };
      const response = await custData({ body, id });
      console.log("response: ", response?.data?.data);
      setCustomerList(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleChatHistory = async (sesId, custName) => {
    setIsActive(!isActive);
    setCustName(custName);
    try {
      setLoad(true);
      const response = await chatHistory(sesId);
      console.log("response: ", response?.data?.data);
      setChatHistoryData(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const onClose = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistoryData]);

  const handleAgentChat = (chat) => {
    if (!chat?.user_input) {
      switch (chat?.type) {
        case "chat_ended":
          return (
            <>
              <p>{chat?.Bot?.welcome_message}</p>
              {chat?.Bot?.options?.map((opt, index) => (
                <p key={index}>{opt?.name}</p>
              ))}
            </>
          );
        case "text_type":
          return <p>{chat?.message?.name}</p>;
        default:
          return <p>{chat?.message?.name}</p>;
      }
    } else {
      switch (chat?.user_input?.type) {
        case "option_type":
          return (
            <>
              {chat?.user_input?.Bot?.welcome_message && (
                <p>{chat?.user_input?.Bot?.welcome_message}</p>
              )}
              <div>
                {chat?.user_input?.Bot?.options?.map((opt) => (
                  <p>{opt?.name}</p>
                ))}
              </div>
            </>
          );
        case "text_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "message_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "wait_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "resolved_type":
          return <p className="chat_left">{chat?.user_input?.message?.name}</p>;

        default:
          return <p>{chat?.user_input}</p>;
      }
    }
  };

  const handleResponse = (chat) => {
    switch (chat?.user_input?.type) {
      case "option_type":
        return (
          <>
            {chat?.user_input?.Bot?.welcome_message && (
              <p className="chat_left">
                {chat?.user_input?.Bot?.welcome_message}
              </p>
            )}
            <div>
              {chat?.user_input?.Bot?.options?.map((opt) => (
                <p className="chat_left">{opt?.name}</p>
              ))}
            </div>
          </>
        );
      case "text_type":
        return <p className="chat_left">{chat?.user_input?.message?.name}</p>;
      case "message_type":
        return <p className="chat_left">{chat?.user_input?.message?.name}</p>;
      case "wait_type":
        return <p className="chat_left">{chat?.user_input?.message?.name}</p>;
      case "resolved_type":
        return <p className="chat_left">{chat?.user_input?.message?.name}</p>;
      case "open_ai_type":
        return <p className="chat_left">{chat?.user_input?.message?.name}</p>;
      case "chat_ended":
        return (
          <>
            <p className="chat_left">
              {chat?.user_input?.Bot?.welcome_message}
            </p>
            <div style={{ justifyContent: "flex-end" }}>
              {chat?.user_input?.Bot?.options?.map((opt) => (
                <p className="chat_left">{opt?.name}</p>
              ))}
            </div>
          </>
        );
      default:
        return <p className="chat_left">{chat?.message?.name}</p>;
    }
  };

  const onChange = (page) => {
    console.log("page: ", page);
    setCurrent(page);
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Chat</h1>
          <div className="srch_add">
            <SelectBox value={client} onChange={filterByAgent} />
            <SearchBox onChange={handleSearch} />
          </div>
        </div>
        <div className="table_box">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Agent Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customerList?.length ? (
                customerList?.map((data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data?.first_name}</td>
                    <td>
                      {data?.agent_name ? data?.agent_name : "Not assigned"}
                    </td>
                    <td>
                      {data?.session ? (
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                handleChatHistory(
                                  data?.session,
                                  data?.first_name
                                )
                              }
                            >
                              <figure>
                                <img src="/images/eye_n.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      ) : (
                        "Not Available"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <Empty />
              )}
            </tbody>
          </table>
          <div className="pagination">
            {customerList?.length && totalCnt > 10 ? (
              <Pagination
                defaultCurrent={1}
                current={current}
                onChange={onChange}
                total={totalCnt}
              />
            ) : null}
          </div>
        </div>
        <Drawer
          title="Agent Chat"
          placement="right"
          onClose={onClose}
          open={isActive}
          width={680}
        >
          <div>
            <div className="person_chat" ref={chatContainerRef}>
              <div className="prsn_head"></div>
              {chatHistoryData &&
                chatHistoryData?.length > 0 &&
                chatHistoryData?.map((data) =>
                  custName === data?.user_name ? (
                    data?.user_input?.type === "agent_assigned" ? (
                      <div className="center_chat">
                        <div className="assigned_message">
                          <p>{data?.user_input?.message?.name}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="rhs_chat">
                        <figure>
                          <img
                            src={
                              data?.profile_pic
                                ? data?.profile_pic
                                : "/images/user.jpeg"
                            }
                            alt="agent"
                          />
                        </figure>
                        <h3>{data?.user_name}</h3>
                        <div className="chat_right">
                          {handleAgentChat(data)}
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="lhs_chat">
                      <figure>
                        <img
                          src={
                            data?.profile_pic
                              ? data?.profile_pic
                              : "/images/user.jpeg"
                          }
                          alt="agent"
                        />
                      </figure>
                      <h3>{data?.user_name}</h3>
                      <div>{handleResponse(data)}</div>
                    </div>
                  )
                )}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default ChatList;
