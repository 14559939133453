/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import { DaysCount } from "../../../utils/daysCount";
import { Spin } from "antd";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import { UploadMedia } from "../../../services/uploadmedia";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  errorToast,
  successToast
} from "../../../helpers/showToast";
import {
  isAlphabete,
  isEmail,
  validMobileNo
} from "../../../utils/validations";
import {
  useAddClntMutation,
  useEditClinetMutation,
  useLazyClientByIdQuery,
  useLazyGetTrailPeriodQuery
} from "../../../services/client";

const ClientField = () => {

  let history = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [phone, setPhone] = useState();
  const [mediaId, setMediaId] = useState()
  const [ClientById] = useLazyClientByIdQuery();
  const [EditClient] = useEditClinetMutation();
  const [trailData, SetTrailData] = useState()
  const [require, setRequire] = useState(false)
  const [trail, setTrail] = useState();
  const [profilePic, SetProfilePic] = useState(null);
  const [profileImg, setProfileImg] = useState("/images/frame.png")
  const location = useLocation();
  const { state } = location;
  const [addClient] = useAddClntMutation();
  const [load, setLoad] = useState(false);
  const [getTrail] = useLazyGetTrailPeriodQuery();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    SetProfilePic(file);
    setProfileImg(URL.createObjectURL(file))
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true)
      const response = await ClientById(state.key);
      const responseData = response?.data?.data
      console.log('response: ', responseData);
      setFirstName(responseData?.first_name)
      setLastName(responseData?.last_name)
      setEmail(responseData?.email)
      setProfileImg(responseData?.profile_picture?.media_file_url)
      setPhone(responseData?.phone_no)
      setTrail(responseData?.trial_period);
      setCompany(responseData?.business_name)
      if (!profilePic) {
        setMediaId(responseData?.profile_picture?.id)
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoad(false)
    }
  }, [ClientById, state, profilePic])


  const fetchTrail = useCallback(async () => {
    let res
    try {
      res = await getTrail()
      SetTrailData(res?.data?.data)
    } catch (error) {
      console.log('error', error)
    }
  }, [getTrail])

  useEffect(() => {
    if (state?.eve === "edit") {
      fetchData();
    }
    fetchTrail()
  }, [fetchData, fetchTrail, state])


  const saveClient = async () => {
    if (state?.eve !== 'edit') {
      if (!email || !firstName || !lastName || !phone || !isEmail(email) || !validMobileNo(phone)) {
        console.log("please enter details")
        setRequire(true)
        return
      }
      let response;
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic)
          proMedia = mediaResponse.data.find((media) => media.id);
        }
        let body = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          profile_picture: profilePic ? proMedia?.id : null,
          phone_no: phone,
          trial_period: JSON.parse(trail),
          business_name: company,
        }
        response = await addClient(body);
        console.log('response: ', response);
        successToast(response?.data?.message);
        history("/clients");
      } catch (error) {
        errorToast(error)
        errorToast(response?.error?.data?.message)
        console.log('error: ', error);
      } finally {
        setLoad(false)
        setFirstName('');
        setLastName('')
        setPhone('');
        setEmail('')
        setProfileImg('/images/frame.png');
        setRequire(false);
      }
    } else if (state.eve === 'edit') {
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic)
          proMedia = await mediaResponse.data.find((media) => media?.id);
        }
        let body = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          business_name: company,
          profile_picture: profilePic ? proMedia?.id : mediaId,
          phone_no: phone,
          trial_period: trail,
        }
        const response = await EditClient({ clientId: state?.key, body });
        console.log('response: ', response);
        successToast(response?.data?.message)
        history("/clients");
      } catch (error) {
        errorToast(error)
        console.log('error: ', error);
      } finally {
        setLoad(false)
      }
    }
  }

  function ClientsPage() {
    history("/clients");
  }

  const phNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setPhone(numericValue);
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFirstName(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLastName(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      saveClient(e);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <Spin spinning={load} size={"medium"}>
          <div className="title_bar d_flx">
            <h1>
              {state?.eve === "edit"
                ? "Edit Client"
                : "Add Cliet"}</h1>
            <div className="srch_add">
              <button
                type="button"
                className="add_btn"
                onClick={ClientsPage}>
                Back
              </button>
            </div>
          </div>
          <div className="add_form d_flx">
            <div className="w_100">
              <div className=" add_profile">
                <figure>
                  <img src={profileImg
                    ? profileImg
                    : "/images/frame.png"}
                    alt="profile" />
                  <input
                    type="file"
                    onChange={handleFileUpload} />
                </figure>
                <p>Upload Image</p>
              </div>
            </div>
            <div className="w_49">
              <LabelInput Name="First Name :" />
              <InputBox
                placeholder="First Name"
                type="text"
                value={firstName}
                onChange={handleFname}
                require={require}
                onKeyDown={handleKeyPress} />
            </div>
            <div className="w_49">
              <LabelInput
                Name="Last Name :" />
              <InputBox
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={handleLname}
                require={require}
                onKeyDown={handleKeyPress} />
            </div>
            <div className="w_49">
              <LabelInput
                Name="Email :" />
              <InputBox
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) =>
                  setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress} />
            </div>
            <div className="w_49">
              <LabelInput
                Name="Company Name:" />
              <InputBox
                placeholder="Company Name"
                type="text"
                value={company}
                onChange={(e) =>
                  setCompany(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress} />
            </div>
            <div className="w_49">
              <LabelInput
                Name="Phone Number :" />
              <InputBox
                placeholder="Phone Number"
                type="text"
                value={phone}
                onChange={phNumber}
                require={require}
                onKeyDown={handleKeyPress} />
            </div>
            <div className="w_49">
              <LabelInput
                Name="Trial Period :" />
              <div className="input_box">
                <select name="client" id="client"
                  value={trail}
                  onChange={(e) =>
                    setTrail(e.target.value)}>
                  <option disabled selected value>
                    Trial Period
                  </option>
                  {trailData?.map((data) =>
                    <option
                      key={data.id}
                      value={data.days}
                    >
                      {DaysCount(data.days)}
                    </option>
                  )}
                </select>
              </div>
              {require
                ? (!trail
                  && <p
                    style={{
                      color: 'red',
                      fontSize: 15,
                      paddingBottom: 2,
                      margin: 0
                    }}
                  >Please select trial period</p>
                ) : null
              }
            </div>
          </div>
          <button
            type="button"
            className="save_btn"
            onClick={saveClient}>
            Submit
          </button>
        </Spin>
      </div>
    </>
  );
};
export default ClientField;
